import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hautausmaa from "../images/hautausmaa.jpg"
const Hautajaisjärjestelyni = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Hautajaisjärjestelyni" />
    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Hautajaisjärjestelyni</h1>
        </div>
      </div>
    </div>
    <div className="spacer-60" />
    <div className="col-md-8 col-md-offset-2">
      <img className="pull-right padding-l25 w-6/12" src={hautausmaa} alt="" />
      <p>
        Toivomukseni siunaamisesta, hautaamisesta, muistotilaisuudesta ja muista
        hautajaisjärjestelyistä. Lataa ja tulosta lomake.
      </p>

      <p>
        <i>
          Huomaa, että lomake latautuu joko uuteen ikkunaan, tai suoraan
          koneelle "Lataukset" nimiseen kansioon (suomenkielisissä
          järjestelmissä).
        </i>
      </p>

      <a href="/doc/hautausj.pdf" className="button button-primary">
        Lataa lomake
      </a>
    </div>
  </Layout>
)

export default Hautajaisjärjestelyni
